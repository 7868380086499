import { Box,  Flex, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Screens = [
  { screen: 'home', title: 'Home', navigate: '/' },
  { screen: 'about', title: 'About', navigate: '/about' },
  { screen: 'services', title: 'Services', navigate: '/services' },
  { screen: 'career', title: 'Career', navigate: '/career' },
];
const Header = () => {
  const [isHovered, setIsHovered] = useState(null);
const currentRoute = useLocation();
  const navigate = useNavigate();
  return (
    <Box>
      <Flex
        bg={'red'}
        flexWrap={'wrap'}
        // margin={5}
        justifyContent={'space-between'}
        px={'2'}
        py={'1'}
        // width={'100%'}
        alignItems={'center'}
        background="linear-gradient( to left, rgba(131, 101, 142, 0.32) 10%, white 80%)"
      >
        <Image
          src={require('../images/vidyaLogo.png')}
          margin={5}
          // resize={'contain'}
          width={150}
          height={50}
        />
        <Text
          borderRadius={5}
          // justifyContent={'center'}
          // alignItems={'center'}
          bg={'#12129B'}
          color={'white'}
          width={100}
          // height={'6%'}
          textAlign={'center'}
          py={1}
          fontFamily={'libreFranklin'}
          fontSize={15}
          cursor={'pointer'}
          marginRight={3}
        >
          Log in
        </Text>
      </Flex>

      <Flex
        flexWrap={'wrap'}
        bg={'white'}
        justifyContent={'space-around'}
        py={3}
        width={'100%'}
      >
        {Screens.map((items, index) => {
          return (
            <Box
              // fontFamily={'libreFranklin'}
              // fontFamily={'abhaya-libre-extrabold'}
              fontFamily={'arvo-bold'}
              fontSize={20}
              
              key={index}
              color={currentRoute.pathname === items.navigate ? 'rgba(183, 35, 35, 1)': 'rgba(18, 18, 155, 1)'}
              position={'relative'}
              cursor={'pointer'}
              onMouseEnter={() => setIsHovered(index)}
              onMouseLeave={() => setIsHovered(null)}
              onClick={() => navigate(items.navigate)}
            >
              <Box fontWeight={800}>
                {items.title}
                {isHovered === index && index === 2 && (
                  <Box
                    position="absolute"
                    bg="white"
                    p={3}
                    fontSize={15}
                    width={{xl:250, lg:250, md:250,sm:220, base:160}}
                    ml={3}
                    boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                    borderRadius="4px"
                    border="1px solid #ddd"
                    zIndex={1}
                  >
                    <ul style={{ listStyle: 'none', padding: 0, margin: 0 }} color='rgba(18, 18, 155, 1)'>
                      <li
                        style={{
                          marginBottom: 5,
                          padding: '5px 0',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate('/services/learning');
                        }}
                      >
                        E-learning Customization
                      </li>
                      <li
                        style={{
                          marginBottom: 5,
                          padding: '5px 0',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate('/services/editorial');
                        }}
                      >
                        Editorial Services
                      </li>
                      <li
                        style={{
                          marginBottom: 5,
                          padding: '5px 0',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate('/services/tutoring');
                        }}
                      >
                        Tutoring
                      </li>
                      <li
                        style={{
                          marginBottom: 5,
                          padding: '5px 0',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate('/services/authoring');
                        }}
                      >
                        Authoring Services
                      </li>
                      <li
                        style={{
                          marginBottom: 5,
                          padding: '5px 0',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate('/services/alignment');
                        }}
                      >
                        Alignment Services
                      </li>
                      <li
                        style={{
                          marginBottom: 5,
                          padding: '5px 0',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate('/services/translation');
                        }}
                      >
                        Translation Services
                      </li>
                    </ul>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};
export default Header;
