import { Form } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
const LetsConnect = () => {
  return (
    <Box backgroundImage={require('../images/WorkWith-Us-2.png')} backgroundSize={'cover'}  backgroundPosition="center" >
      <Header />
      <Box>
      <Text fontFamily={'arvo-regular'} ml={{xl:'7%', lg:'8%', md:'4%', sm:'15%' , base:'9%'}} textAlign={'start'}  p={5} color={'rgba(7, 15, 89, 1)'} fontWeight={800} fontSize={42}>
          Let’s Connect!
        </Text>
        <Flex flexDirection={{xl:'row', lg:'row', md:'row', base:'column'}} alignItems={'center'} justifyContent={'space-around'}>
          <FormControl width={{xl:'43%',lg:'44%', md:'50%',sm:'55%', base:'100%'}}>
          
            <Box bg={'white'} m={5}>
              <FormLabel
                mx={5}
                pt={3}
                fontFamily={'arvo-regular'}
                fontWeight={600}
                
              >
                Email:
              </FormLabel>
              <Input
                border={'none'}
                _focus={{
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none', // Ensure the default box shadow is removed
                }}
                boxShadow={'2px 3px 4px 0px #00000040'}
                type="email"
              />
            </Box>
            <Box bg={'white'} m={5}>
              <FormLabel
                pt={3}
                mx={5}
                fontFamily={'arvo-regular'}
                fontWeight={600}
              >
                Name:
              </FormLabel>
              <Input
                border={'none'}
                _focus={{
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none', // Ensure the default box shadow is removed
                }}
                boxShadow={'2px 3px 4px 0px #00000040'}
                type="text"
              />
            </Box>
            <Box bg={'white'} m={5}>
              <FormLabel
                pt={3}
                mx={5}
                fontFamily={'arvo-regular'}
                fontWeight={600}
              >
                Company name:
              </FormLabel>
              <Input
                border={'none'}
                _focus={{
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none', // Ensure the default box shadow is removed
                }}
                boxShadow={'2px 3px 4px 0px #00000040'}
                type="text"
              />
            </Box>
            <Box bg={'white'} m={5}>
              <FormLabel
                pt={3}
                mx={5}
                fontFamily={'arvo-regular'}
                fontWeight={600}
              >
                Phone number:
              </FormLabel>
              <Input
                border={'none'}
                _focus={{
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none', // Ensure the default box shadow is removed
                }}
                boxShadow={'2px 3px 4px 0px #00000040'}
                type="number"
              />
            </Box>
            <Box bg={'white'} m={5}>
              <FormLabel
                pt={3}
                mx={5}
                fontFamily={'arvo-regular'}
                fontWeight={600}
              >
                How did you hear about us ?:
              </FormLabel>
              <Input
                border={'none'}
                _focus={{
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none', // Ensure the default box shadow is removed
                }}
                boxShadow={'2px 3px 4px 0px #00000040'}
                type="text"
              />
            </Box>
            <Box bg={'white'} m={5}>
              <FormLabel
                pt={3}
                mx={5}
                fontFamily={'arvo-regular'}
                fontWeight={600}
              >
                Message:
              </FormLabel>
              <Input
              p={10}
                border={'none'}
                _focus={{
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none', // Ensure the default box shadow is removed
                }}
                boxShadow={'2px 3px 4px 0px #00000040'}
                type="text"
              />
            </Box>
            <Flex alignItems={'center'} justifyContent={'center'}>
            <Image
              cursor={'pointer'}
              m={5}
              width={{xl:615, lg:500, md:'87%', base:'87%'}}
              src={require('../images/submitBtn.png')}
            />
        </Flex>
          </FormControl>
        
      <Box color={'white'} width={{xl:'30%'}}>
        <Box my={10} mx={3} display={'flex'} justifyContent={'center'} bg={'rgba(52, 58, 116, 1)'}>
          <Image
            src={require('../images/vidyaCircularLogo.png')}
            height={200}
            width={200}
          />
        </Box>
        <Box p={8} m={3} bg={'rgba(52, 58, 116, 1)'}  display={'flex'} alignItems={'center'} flexDirection={'column'} justifyContent={'center'}>
          <Text fontWeight={800} fontSize={22}>GET IN TOUCH</Text>
          <Flex flexDirection={{xl:'row', lg:'row', md:'row', base:'c'}} my={3} justifyContent={'space-between'}>
            <Image mr={3} height={5} src={require('../images/telephone.png')} />
            <Text fontFamily={'arvo-regular'}> +91731-4985066</Text>
          </Flex>
          <Flex>
            <Image mr={3} height={5} fontFamily={'arvo-regular'} src={require('../images/msg.png')} />
            <Text fontFamily={'arvo-regular'}>info@vidyaa-tech.com</Text>
          </Flex>
        </Box>
        <Box mx={3} my={10} p={8} bg={'rgba(52, 58, 116, 1)'}>
          <Flex justifyContent={'center'}>
            <Image height={5} src={require('../images/location.png')} />
            <Text fontFamily={'arvo-regular'}>
              <Text>S-4 Agrawals, 4th floor</Text>
              <Text>YN road, Indore - 452001</Text>Madhya Pradesh, India
            </Text>
          </Flex>
        </Box>
      </Box></Flex>
      <Footer/>
    </Box>
    </Box>
  );
};
export default LetsConnect;